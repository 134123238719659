import React from "react"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
// import SpotlightEvent from "../components/talks/spotlight"
// import MoreEvents from "../components/talks/more_commb_talks"
import PastEvents from "../components/talks/past_commb_talks"
import { useStaticQuery, graphql } from "gatsby"

const Events = () => {
  // const { contentfulTalks } = useStaticQuery(graphql`
  //   {
  //     contentfulTalks {
  //       info {
  //         info
  //       }
  //       label
  //       url
  //     }
  //   }
  // `)
  return (
    <div className="eventsPage talksPage">
      <Layout>
        <Seo title="COMMB Talks" />
        {/* <div className="events_page">
          <div className="main_content_wrapper insight_page">
            <div className="spotlight_article_section">
              <div className="container">
                <SpotlightEvent />
                <div className="talksWrapper">
                  {contentfulTalks.info.info}
                  <p className="signupWrapperBtnTalks">
                    <a href={contentfulTalks.url} className="eventsCTABtn">
                      {contentfulTalks.label}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="38.938"
                        height="8.071"
                        viewBox="0 0 38.938 8.071"
                      >
                        <g transform="translate(-1192.844 -692.433)">
                          <path
                            data-name="Icon open-arrow-left"
                            d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                            transform="translate(1231 699.427) rotate(180)"
                            fill="#e15b5f"
                          ></path>
                          <path
                            data-name="Icon open-arrow-left - Outline"
                            d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                            transform="translate(1231 699.427) rotate(180)"
                            fill="#e15b5f"
                          ></path>
                        </g>
                      </svg>
                    </a>
                  </p>
                </div>
                <MoreEvents />
                <PastEvents />
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    </div>
  )
}

export default Events
